import LR from "../../../components/LeftRight.module.css";

import { LeftRight } from "../../../components/LeftRight.tsx";
import { WSelect, type ConfiguredWSelectProps } from "../../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../../components/forms/WSelectWrapper.tsx";
import { ResourceIcon } from "../../../components/icon/ResourceIcon.tsx";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import type { VirtualMachineLoc } from "../../compute/vmQuery.ts";
import * as vmListQuery from "../../compute/vmQuery.ts";
import * as vpcListQuery from "../vpc/apiOperations.ts";

export interface TargetServersSelectParams {
    location: string;
    networkUuid?: string;
    usedResourceUuids: string[];
}

export interface TargetServersSelectProps
    extends ConfiguredWSelectProps<VirtualMachineLoc, string>,
        TargetServersSelectParams {}

//#region Icons

function ShortInfo({ item }: { item: VirtualMachineLoc }) {
    return <span>{item.private_ipv4}</span>;
}

//#endregion

export function TargetServersSelectCustom({
    networkUuid,
    items,
    location,
    usedResourceUuids = [],
    ...props
}: TargetServersSelectProps) {
    const { onChange } = props;
    const vpcs = useSuspenseQueryAtom(vpcListQuery.vpcQueryAtom(location));

    // TODO: should networkUuid be required?
    const vpc = networkUuid ? vpcs.get(networkUuid) : null;
    items = items.filter((item) => vpc?.vm_uuids.includes(item.uuid) && !usedResourceUuids.includes(item.uuid));

    return (
        <WSelect
            className="min-w-20em"
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select target server"
            items={items}
            getKey={(item) => item.uuid}
            getTextValue={(item) => item.name}
            searchItems={(query, items) =>
                items.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
            }
            onChange={onChange}
            {...props}
        >
            {(item) => (
                <LeftRight icon={<ResourceIcon item={item} />} title={item.name} right={<ShortInfo item={item} />} />
            )}
        </WSelect>
    );
}

export type TargetServersSelectLoaderProps = Omit<TargetServersSelectProps, "items">;

function TargetServersSelectLoader(props: TargetServersSelectLoaderProps) {
    const vms = vmListQuery.useSuspense();

    return <TargetServersSelectCustom items={[...vms.values()]} {...props} />;
}

export function TargetServersSelect(props: TargetServersSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <TargetServersSelectCustom {...props} {...renderProps} />}>
            <TargetServersSelectLoader {...props} />
        </WSelectWrapper>
    );
}
