// TODO: Use proper, separate styles for this component
import LR from "../../components/LeftRight.module.css";

import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type ConfiguredWSelectProps } from "../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../components/forms/WSelectWrapper.tsx";
import { ResourceIcon } from "../../components/icon/ResourceIcon.tsx";
import { UnassignedItem } from "./UnassignedItem.tsx";
import {
    UNASSIGNED_RESOURCE,
    getDisplayName,
    getResourceStringId,
    type IpAssignableResource,
} from "./ipAddress/resourceId.ts";
import { useIpAssignableQuery } from "./ipAddress/resourceQuery.ts";

export interface AssignToSelectProps
    extends ConfiguredWSelectProps<IpAssignableResource | typeof UNASSIGNED_RESOURCE, string> {}

export function AssignToSelectCustom(props: AssignToSelectProps) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select resource to assign to"
            getKey={getResourceStringId}
            getTextValue={getDisplayName}
            searchItems={(query, items) => {
                const q = query.toLowerCase();
                return items.filter((item) => getDisplayName(item).toLowerCase().includes(q));
            }}
            viewer={(item) =>
                item !== UNASSIGNED_RESOURCE && item !== undefined ? (
                    <LeftRight
                        icon={<ResourceIcon item={item} size="size-1.1em mb-[-0.25em]" />}
                        title={getDisplayName(item)}
                    />
                ) : (
                    <span className="text-muted">{getDisplayName(item ?? null)}</span>
                )
            }
            renderUnknown={() => <span className="text-error">Unknown resource</span>}
            {...props}
        >
            {(item) =>
                item !== UNASSIGNED_RESOURCE ? (
                    <LeftRight icon={<ResourceIcon item={item} />} title={getDisplayName(item)} />
                ) : (
                    <UnassignedItem />
                )
            }
        </WSelect>
    );
}

export interface AssignToSelectLoaderProps extends Omit<AssignToSelectProps, "items"> {
    location: string;
}

function AssignToSelectLoader({ location, ...props }: AssignToSelectLoaderProps) {
    const items = useIpAssignableQuery(location);

    return <AssignToSelectCustom items={[UNASSIGNED_RESOURCE, ...items]} {...props} />;
}

export function AssignToSelect(props: AssignToSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <AssignToSelectCustom {...props} {...renderProps} />}>
            <AssignToSelectLoader {...props} />
        </WSelectWrapper>
    );
}
