export const baLabels = {
    address_line1: "Street address",
    address_line2: "Address line 2",
    city: "City",
    company_name: "Company name",
    company_reg_code: "Registration number",
    company_vat_number: "VAT number",
    country: "Country",
    county: "County / State",
    customer_id_number: "Personal ID number",
    customer_name: "Contact person's name",
    customer_phone: "Contact person's phone",
    email: "Invoice email",
    post_index: "Zip/postal code",
    referral_code: "Campaign code",
    title: "Billing account name",
} as const;
