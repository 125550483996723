import {
    EditableBillingSelect,
    type EditableBillingSelectProps,
} from "../../modules/billing/forms/EditableBillingSelect.tsx";
import { DetailsTableRow } from "./DetailsTableRow.tsx";

export interface DetailsTableBillingAccountProps extends Omit<EditableBillingSelectProps, "isReadOnly"> {
    isGlobal?: boolean;
}

export function DetailsTableBillingAccount({ isGlobal, ...props }: DetailsTableBillingAccountProps) {
    const isReadOnly = !props.onChange;
    return (
        <DetailsTableRow title="Billing account:">
            {isGlobal ? "All" : <EditableBillingSelect isReadOnly={isReadOnly} {...props} />}
        </DetailsTableRow>
    );
}
