import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardSuspenseQuery } from "../../api/useStandardMutation.ts";
import * as vmQuery from "../../compute/vmQuery.ts";
import { allServicesQueryAtom, type ManagedServiceLoc } from "../../services/servicesQuery.ts";
import { getSingleQuery } from "../ipAddress/apiOperations.ts";
import * as loadBalancerQuery from "../loadbalancer/apiOperations.ts";
import type { AssignedIpAddress } from "./assignable.ts";
import type { IpAssignableResource } from "./resourceId.ts";

export function useIpAssignableQuery(location: string): IpAssignableResource[] {
    const { data: vms } = useStandardSuspenseQuery(vmQuery.getSingleQuery, { location });
    const { data: balancers } = useStandardSuspenseQuery(loadBalancerQuery.getSingleQuery, { location });
    const services = useSuspenseQueryAtom(allServicesQueryAtom);

    // TODO: might not be the best solution
    const locationServices = location
        ? [...services.values()].filter((s: ManagedServiceLoc) => s.location === location)
        : [...services.values()];

    return [...vms.values(), ...balancers.values(), ...locationServices];
}

export function useAssignableIpAddressQuery(location: string): AssignedIpAddress[] {
    const { data } = useStandardSuspenseQuery(getSingleQuery, { location });
    return [...data.values()];
}
