import type { editableFields } from "@warrenio/api-spec/spec/billing.editableFields";
import { objFromEntries } from "@warrenio/utils/collections/objs";
import { atom } from "jotai/vanilla";
import { configAtom } from "../../../config.ts";
import type { BillingAccountField } from "./BillingAccountField.types.ts";

export type EditableFieldName = (typeof editableFields)[number];

/** A {@link BillingAccountField} with all optional properties filled with defaults */
export type ConfiguredBillingAccountField = Required<BillingAccountField>;

/** Pre-processed billing account field visibility config (keyed by field name) */
export const baFieldsConfigAtom = atom(
    (get): Partial<Record<BillingAccountField["field"], ConfiguredBillingAccountField>> => {
        const { billingAccountFields } = get(configAtom);
        return objFromEntries(
            [
                // NB: Even add the `title` field for more uniform handling in code
                { field: "title", required: true },
                { field: "referral_code", required: false },
                ...billingAccountFields,
            ],
            (f) => [f.field, { visible: true, ...f }],
        );
    },
);
